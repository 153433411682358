import React from "react";
import FirstQuote from "../FirstQuote";
// import ProdRecentiTitle from "../ProdRecentiTitle";
// import ProdRecentiGrid from "../ProdRecentiGrid";
import Collaboration from "../Collaboration";




const MainContent = () => {
  return (
    <div>
      <FirstQuote />
      {/* <ProdRecentiTitle />
      <ProdRecentiGrid /> */}
      <Collaboration />
    </div>
  );
};

export default MainContent;
