import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useIsMobile from "../../hooks/useIsMobile";
import CustomText from "../CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    padding: "340px 16px",
  },
  quoteRoot: {
    width: 0,
    height: 0,
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    display: "flex",
  },
  selectedQuoteRoot: {
    height: 200,
    opacity: 1,
    width: "100%",
    transition: "opacity 1.5s ease-in-out",
  },
  shared: {
    marginBottom: 11,
  },
  sharedContainer: {
    maxWidth: 201,
    textAlign: "end",
  },
  line: {
    margin: "0px 35px",
    borderTop: "1px solid #000000",
    width: 100,
  },
  quote: {
    maxWidth: 652,
  },
  landQuoteRoot: {
    flexDirection: "column-reverse",
    alignItems: "start",
  },
  landLine: {
    margin: "32px 0",
  },
  landSharedContainer: {
    textAlign: "left",
  },
  landSection: {
    padding: "200px 16px",
  },
}));

const FirstQuote = () => {
  const classes = useStyles();
  const { isMobileClasses } = useIsMobile();
  const [current, setCurrent] = useState(0);

  const quotes = [
    {
      cit: "La speranza è una cosa buona, forse la migliore delle cose, e le cose buone non muoiono mai",
      name: "Andy",
      film: "Le ali della libertà",
    },
    {
      cit: "Mi fa male il Gulliver, ma'. Lasciami in pace, dormendo mi passa",
      name: "Alexander DeLarge",
      film: "Arancia Meccanica",
    },
    {
      cit: "Ti do due minuti per disegnare un labirinto che ne richieda uno per risolverlo",
      name: "Dom Cobb",
      film: "Inception",
    },
    {
      cit: "Più è pesante l'uomo, più profonde sono le sue impronte",
      name: "Alfredo",
      film: "Nuovo Cinema Paradiso",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrent(current);
      current === 3 ? setCurrent(0) : setCurrent(current + 1);
    }, 6000);
    return () => clearTimeout(timer);
  }, [current]);

  return (
    <section
      className={isMobileClasses({
        webClass: classes.section,
        landClass: classes.landSection,
      })}
    >
      {quotes.map((citazione, idx) => (
        <div
          key={citazione.name}
          className={isMobileClasses({
            webClass: clsx(classes.quoteRoot, {
              [classes.selectedQuoteRoot]: current === idx,
            }),
            landClass: classes.landQuoteRoot,
          })}
        >
          <div
            className={isMobileClasses({
              webClass: classes.sharedContainer,
              landClass: classes.landSharedContainer,
            })}
          >
            <CustomText type="payoff2" className={classes.shared}>
              SHARED BY
            </CustomText>
            <CustomText type="paragrafo">{citazione.name}</CustomText>
            <CustomText type="paragrafo">{citazione.film}</CustomText>
          </div>
          <div
            className={isMobileClasses({
              webClass: classes.line,
              landClass: classes.landLine,
            })}
          />
          <CustomText type="titoloH3Sezioni" className={classes.quote}>
            {citazione.cit}
          </CustomText>
        </div>
      ))}
    </section>
  );
};

export default FirstQuote;
