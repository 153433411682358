import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  children: JSX.Element|JSX.Element[];
}

const useStyles = makeStyles((theme: Theme) => ({
  hero: {
    /* Sizing */
    //height: "200vh",
    /* Background styles */
    position:"relative"
  },
}));

const Hero = ({ children }: Props) => {
  const classes = useStyles();
  return <section className={classes.hero}  >{children}</section>;
};

export default Hero;
