import React from "react";
import { ThemeProvider } from "../../Utils/themeProvider";
import { App } from "../Components/App";
import "../stylesheet.css";
import "../font.css";
import "../index.css";
import { Helmet } from "react-helmet";

export default function IndexPage() {
  return (
    <ThemeProvider>
      <Helmet>
        <title>Verteego – Entertainment</title>
        <meta name="description" content="Verteego Entertainment è il ramo dell’azienda rivolto alla produzione audiovisiva, con uno sguardo attento alla produzione di format TV, videoclip musicali e contenuti pubblicitari." />
      </Helmet>
      <App />
    </ThemeProvider>
  );
}
