import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme: Theme) => ({
  heroVideo: {
    height: "100vh",
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 0,
  },
}));

const HeroVideo = () => {
  const classes = useStyles();
  return (
    <div className={classes.heroVideo}>
      <ReactPlayer
        className="videoHomepage"
        muted
        width="100%"
        height="100%"
        loop
        playing
        playsinline
        url="https://verteego.it/video/showreel-verteego.mp4"
      />
    </div>
  );
};

export default HeroVideo;